.buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.defaultButton {
    height: 35px;
    width: 50px;
    background-color: #333;
    outline: none;
    border: none;
    display: inline-block;
    color: #eee;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

.defaultButton:hover,
.defaultButton:focus,
.defaultButton:active {
    box-shadow: none;
    background-color: #333 !important;
}

.timezoneSelect {
    height: 30px, ;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    outline: none;
    cursor: pointer;
}

.content-import-export {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
    border: none;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}