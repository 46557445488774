.pointer {
    cursor: pointer;
}

.selected-shape {
    background-color: #d9edf7 !important;
}


.action-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: black;
    border: 0;
    gap: 1rem;
}

.action-button img {
    cursor: pointer;
}

.action-button:hover,
.action-button:active,
.action-button:focus {
    background-color: #00000013 !important;
    color: black !important;
    box-shadow: none !important;
}

.action-button:disabled {
    background-color: transparent !important;
    color: #5f5959;
    box-shadow: none !important;
}

.action-button:focus-visible {
    background-color: transparent !important;
    color: black !important;
    box-shadow: none !important;
}

.tableFeatures {
    font-size: 0.7rem;
    overflow-y: auto;
}

.modal-features-content {
    max-height: 90dvh !important;
    overflow-x: auto;
}

.modal-features-dialog {
    max-width: 100dvh !important;
}